<template>
  <div class="examine">
    <div class="container">
      <el-card>
        <el-tabs v-model="queryInfo.applyStatus" @tab-click="search">
          <el-tab-pane label="待审核" name="1">
            <div class="search">
              <div class="search_right">
                <el-input
                  placeholder="请输入企业全称、用户名称"
                  v-model="queryInfo.queryConditions"
                  class="input-with-select"
                  clearable
                >
                  <el-button slot="append" @click="changeSearchangeSearch">搜索</el-button>
                </el-input>
              </div>
            </div>
            <el-table
              v-loading="loading"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              class="table_data"
              :data="tableData"
              :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
              ref="multipleTable"
              style="width: 100%"
              tooltip-effect="dark"
              :row-key="getRowKey"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
              <el-table-column prop="id" label="编号" align="center" width="80"> </el-table-column>
              <el-table-column label="微信头像" align="center" width="100">
                <template v-slot="{ row }">
                  <img v-if="row.headImgUrl" :src="row.headImgUrl" alt="" class="img" />
                  <img
                    v-else
                    src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/202302061802.png"
                    class="img"
                    alt=""
                  />
                </template>
              </el-table-column>
              <el-table-column prop="realName" label="姓名" align="center" width="80">
              </el-table-column>
              <el-table-column label="性别" align="center" width="60">
                <template v-slot="{ row }">
                  <div class="sex" v-if="row.sex == '1'">男</div>
                  <div class="sex" v-else>女</div>
                </template>
              </el-table-column>
              <el-table-column label="用户类型" align="center" width="100">
                <template v-slot="{ row }">
                  <div class="type_can" v-if="row.wechatUserFlag == '1'">
                    <em></em>
                    <span>智参用户</span>
                  </div>
                  <div class="type_xuan" v-else>
                    <em></em>
                    <span>智选用户</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="position" label="职位" align="center"> </el-table-column>
              <el-table-column prop="phone" label="手机号" width="120" align="center">
              </el-table-column>
              <el-table-column prop="address" label="所在城市" align="center"> </el-table-column>
              <el-table-column prop="companyFullName" label="企业全称" align="center" width="240px">
                <template v-slot="{ row }">
                  <div>{{ row.companyFullName }}</div>

                  <div style="color: #ff7878; float: left" v-if="row.companyIsExist == '0'">
                    <i class="el-icon-warning"></i>
                    注意：此企业不是数据库内企业
                  </div>
                  <div style="color: #ff7878; float: left" v-if="row.userUpdateIdentity == '1'">
                    <i class="el-icon-warning"></i>
                    注意：用户修改了个人信息
                  </div>
                  <!-- <div style="color: #ff7878;float: left;" v-if="row.accountIsExist == '1'">
                    <i class="el-icon-warning"></i>
                    注意：此企业已有一个主账号</div
                  > -->
                </template>
              </el-table-column>
              <el-table-column prop="businessCardList" label="名片" align="center">
                <template v-slot="{ row }">
                  <div v-if="row.businessCardList">
                    <div class="img_list" v-for="(item, index) in row.businessCardList" :key="index">
                      <el-image
                        style="width: 70px; height: 40px"
                        :src="item"
                        :preview-src-list="row.businessCardList"
                      ></el-image>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="申请时间"
                sortable
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column label="操作" align="center" width="140px">
                <template v-slot="{ row }">
                  <!-- 智选用户wechatUserFlag:2, 智参用户wechatUserFlag:1 -->
                  <div class="btn" v-if="row.wechatUserFlag == 1">
                    <span class="pass" @click="passClick(row)">通过</span>
                    <span class="un_pass" @click="unPassClick(row)">不通过</span>
                  </div>
                  <div class="btn" v-if="row.wechatUserFlag == 2">
                    <span class="pass" @click="smartApplyPass(row)">通过</span>
                    <span class="un_pass" @click="unPassClick(row)">不通过</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="已审核" name="2">
            <div class="search">
              <div class="search_right">
                <el-input
                  placeholder="请输入企业全称、用户名称"
                  v-model="queryInfo.queryConditions"
                  class="input-with-select"
                  clearable
                >
                  <el-button slot="append" @click="changeSearchangeSearch">搜索</el-button>
                </el-input>
              </div>
            </div>
            <el-table
              v-loading="loading"
              element-loading-spinner="el-icon-loading"
              class="table_data"
              :data="tableData"
              :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
              ref="multipleTable"
              style="width: 100%"
              tooltip-effect="dark"
              :row-key="getRowKey"
              @sort-change="changeSort"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
              <el-table-column prop="id" label="编号" align="center" width="80"> </el-table-column>
              <el-table-column label="微信头像" align="center" width="100">
                <template v-slot="{ row }">
                  <img v-if="row.headImgUrl" :src="row.headImgUrl" alt="" class="img" />
                  <img
                    v-else
                    src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/202302061802.png"
                    class="img"
                    alt=""
                  />
                </template>
              </el-table-column>
              <el-table-column prop="realName" label="姓名" align="center" width="80">
              </el-table-column>
              <el-table-column label="性别" align="center" width="60">
                <template v-slot="{ row }">
                  <div class="sex" v-if="row.sex == '1'">男</div>
                  <div class="sex" v-else>女</div>
                </template>
              </el-table-column>
              <el-table-column label="用户类型" align="center" width="100">
                <template v-slot="{ row }">
                  <div class="type_can" v-if="row.wechatUserFlag == '1'">
                    <em></em>
                    <span>智参用户</span>
                  </div>
                  <div class="type_xuan" v-else>
                    <em></em>
                    <span>智选用户</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="审核状态" align="center" width="100">
                <template v-slot="{ row }">
                  <div class="success" v-if="row.applyStatus == '2'">审核通过</div>
                  <div class="error" v-if="row.applyStatus == '3'">
                    <p>审核不通过</p>
                    <el-popover trigger="hover" class="popover" placement="right">
                      <div slot="reference">
                        <span class="span">{{ row.refuseReason }}</span>
                      </div>
                      <span class="new_span">{{ row.refuseReason }}</span>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="position" label="职位" align="center"> </el-table-column>
              <el-table-column prop="phone" label="手机号" width="120" align="center">
              </el-table-column>
              <el-table-column prop="address" label="所在城市" align="center"> </el-table-column>
              <el-table-column prop="companyFullName" label="企业全称" align="center">
                <template v-slot="{ row }">
                  <span>{{ row.companyFullName }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="businessCardList" label="名片" align="center">
                <template v-slot="{ row }">
                  <div class="img_list">
                    <el-popover
                      trigger="hover"
                      max-width="350"
                      v-for="(item, index) in row.businessCardList"
                      :key="index"
                      class="popover"
                      placement="left"
                    >
                      <div slot="reference">
                        <img class="new_img" width="350" height="200" :src="item" />
                      </div>
                      <img class="new_img" width="350" height="200" :src="item" />
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="提交时间"
                sortable
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="applyTime"
                label="审核时间"
                sortable
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column prop="userName" label="审核人" align="center"></el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
    <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    <!-- 拒绝的理由弹窗 -->
    <el-dialog title="不通过" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="refuse">
        <el-form
          :model="refuseObj"
          :rules="rules"
          ref="ruleForm"
          label-width="60px"
          class="demo-ruleForm"
        >
          <el-form-item label="理由" prop="refuseReason">
            <el-input
              type="textarea"
              :rows="10"
              placeholder="请输入不通过理由"
              v-model="refuseObj.refuseReason"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="通过审核" :visible.sync="open" width="30%" :before-close="handleClose">
      <div class="refuse">
        <el-form
          :model="params"
          :rules="paramrules"
          ref="ruleForms"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item label="关联企业" prop="companyId">
            <el-autocomplete
              v-model="params.keyWord"
              :fetch-suggestions="
                (res, cb) => {
                  return querySearchAsyncapts2(res, cb)
                }
              "
              placeholder="请搜索企业"
              @select="
                (res) => {
                  return handleSelectcapts2(res)
                }
              "
            >
              <template slot-scope="{ item }">
                <div class="name">
                  <img
                    :src="item.companyLogo"
                    style="border-radius: 28px"
                    width="28px"
                    height="28px"
                    alt=""
                  />
                  <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                    item.companyFullName
                  }}</span>
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="passcompany">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  serverApiUserAuditPage,
  applyNoPass,
  smartApplyPass,
  smartApplyNoPass,
  applyPass,
  selectByCompanyFullName
} from '@/api/user'
import { listCompanyFullName } from '@/api/clue'

import { pagination } from '@/mixins/pagination'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  applyStatus: '1', //申请状态;1是待审核，2是已审核
  beginTime: '', //账号开始周期
  city: '', //所在地区
  deadline: '', //账号结束周期
  formalUser: '', //是否是正式用户;1是正式用户，2非正式用户,全部传''
  orderByParam: 'y.apply_time DESC', //排序字段+ DESC或ASC
  position: '', //职位;1= CEO, 2 = CIO, 3=COO,4=IT负责人,5=项目负责人,6=其他
  province: '', //所在省份
  queryConditions: '', //查询条件
  wechatUserFlag: '' //小程序用户标识；1是智参，2是智选,''是全部
})
const defaultRefuseObj = Object.freeze({
  id: '', //当前用户的id
  refuseReason: '' //拒绝的理由
})
export default {
  name: 'Examine',
  mixins: [pagination],
  data() {
    return {
      elementHeight: 50,
      rules: {
        refuseReason: [{ required: true, message: '请输入不通过理由', trigger: 'blur' }]
      }, //提交理由的验证表单
      paramrules: {
        companyId: [{ required: true, message: '请选择企业', trigger: 'change' }]
      },
      dialogVisible: false, //拒绝理由的弹窗
      open: false, //通过窗口
      refuseObj: { ...defaultRefuseObj },
      // 遮罩层
      loading: true,
      tableData: [], //表格数据
      queryInfo: { ...defaultQueryInfo },
      params: {
        id: null,
        keyWord: null,
        companyId: null
      },
      paramsSmart: {
        applyId: null,
        companyId: null
      },
      wechatUserFlag: '' //1智参用户2智选用户
    }
  },
  created() {
    //页面加载完成  调取数据
    this.search()
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     // this.$refs.element.$el.offsetTop：元素距离浏览器顶部的高度
  //     this.elementHeight = window.innerHeight - this.$refs.element.$el.offsetTop;
  //
  //     // 监听窗口大小变化
  //     let context = this;
  //     window.onresize = () => {
  //       context.elementHeight = window.innerHeight - context.$refs.element.$el.offsetTop;
  //     }
  //   })
  // },
  methods: {
    //提交按钮拒绝理由
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.wechatUserFlag == 2) {
            const query = {
              applyId: this.refuseObj.id,
              refuseReason: this.refuseObj.refuseReason
            }
            const { data: res } = await smartApplyNoPass(query)
            if (res.resultCode === 200) {
              this.$message.success('提交成功')
              this.search()
              this.dialogVisible = false
            } else {
              this.$message.error('拒绝失败')
            }
          } else {
            const { data: res } = await applyNoPass(this.refuseObj)
            if (res.resultCode === 200) {
              this.$message.success('提交成功')
              this.search()
              this.dialogVisible = false
            } else {
              this.$message.error('拒绝失败')
            }
          }
        } else {
          this.$message.error('请输入拒绝理由')
        }
      })
    },
    //关闭拒绝理由的弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          Object.assign(this.refuseObj, defaultRefuseObj)
          this.dialogVisible = false
          done()
        })
        .catch((_) => {})
    },
    //通过审核
    passClick(row) {
      this.wechatUserFlag = row.wechatUserFlag
      this.params.id = row.applyId
      if (row.companyIsExist == 1) {
        this.submitpass(1)
      } else {
        this.open = true
        this.params.keyWord = ''
      }
    },

    async querySearchAsyncapts2(queryString, cb) {
      if (this.params.keyWord) {
        if (this.wechatUserFlag == 1) {
          selectByCompanyFullName({
            companyFullName: this.params.keyWord
          }).then((response) => {
            if (response.data.resultCode === 200) {
              this.specilist = response.data.data
              cb(this.specilist)
            }
          })
        } else {
          listCompanyFullName({
            pageNum: 1,
            pageSize: 20,
            companyFullName: this.params.keyWord
          }).then(({data:response}) => {

            if (response.resultCode === 200) {
              this.specilist = response.data.list
              cb(this.specilist)
            }
          })
        }
      } else {
        cb(this.specilist)
      }
    },

    //选择企业
    handleSelectcapts2(item) {
      this.params.keyWord = item.companyFullName
      this.params.companyId = item.id
      this.paramsSmart.companyId = item.id
    },
    //智参企业审核通过
    submitpass(index) {
      //index判断修改的信息是否是存在的企业
      if (index) {
        const that = this
        this.$alert('您是否确认审核？')
          .then(function () {
            return applyPass(that.params)
          })
          .then(() => {
            this.search()
            this.$message.success('审核成功')
          })
          .catch(() => {})
      }
    },
    //智选审核通过
    smartApplyPass(row) {
      this.wechatUserFlag = row.wechatUserFlag
      this.paramsSmart.applyId = row.applyId
      if (row.companyIsExist == 1) {
        this.SmartPass(row)
      } else {
        this.open = true
        this.params.keyWord = ''
      }
    },
    //数据库存在智选企业审核通过
    SmartPass(row) {
      const query = {
        applyId: row.applyId,
        companyId: ''
      }
      const that = this
      this.$alert('您是否确认审核？')
        .then(function () {
          return smartApplyPass(query)
        })
        .then(() => {
          this.search()
          this.$message.success('审核成功')
        })
        .catch(() => {})
    },
    //
    //数据库不存在企业的·1审核
    passcompany() {
      this.$refs['ruleForms'].validate((valid) => {
        if (valid) {
          if (this.wechatUserFlag == '1') {
            applyPass(this.params).then((response) => {
              if (response.data.resultCode === 200) {
                this.$message.success(response.data.message)
                this.search()
                this.open = false
              } else {
                this.$message.error('网络错误请重试')
                this.search()
              }
            })
          } else {
            smartApplyPass(this.paramsSmart).then((response) => {
              if (response.data.resultCode === 200) {
                this.$message.success(response.data.message)
                this.search()
                this.open = false
              } else {
                this.$message.error('网络错误请重试')
                this.search()
              }
            })
          }
        }
      })
    },
    //不通过审核
    unPassClick(row) {
      this.refuseObj.id = row.applyId
      this.dialogVisible = true
      this.wechatUserFlag = row.wechatUserFlag
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    //保存首页勾选数据
    getRowKey(row) {
      return row.applyId
    },
    //获取表格数据
    async search() {
      this.loading = true
      const { data: res } = await serverApiUserAuditPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'applyTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 'y.apply_time ASC'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 'y.apply_time DESC'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 'y.apply_time DESC'
          this.search()
        }
      }
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    },
  }
}
</script>

<style lang="less" scoped>
::v-deep .refuse .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 300px;
}
.examine {
  width: 100%;
  /deep/ .el-tabs__item {
    font-size: 20px;
    color: #999999;
  }

  /deep/ .el-tabs__item.is-active {
    color: #4e93fb !important;
  }

  .container {
    height: calc(100vh - 150px);
    overflow: auto;
  }

  .table_data {
    .success {
      color: #ff7d18;
      font-size: 14px;
      font-weight: 400;
    }

    .error {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;

      p {
        color: #f53939;
        font-size: 14px;
        font-weight: 400;
      }

      .span {
        color: #999999;
        font-size: 12px;
        display: inline-block;
        width: 100%;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .new_span {
        color: #999999;
        line-height: 1.5em;
        font-size: 12px;
        cursor: pointer;
        font-weight: 400;
      }
    }

    .img {
      width: 42px;
      height: 42px;
      display: block;
      margin: 0 auto;
      border-radius: 4px;
    }

    .type_can {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #ff7d18;
        border-radius: 50%;
      }

      span {
        color: #ff7d18;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .type_xuan {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #001aff;
        border-radius: 50%;
      }

      span {
        color: #001aff;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .formal {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(78, 147, 251, 0.1);
      color: #4e93fb;
      border-radius: 2px;
    }

    .tourist {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 125, 24, 0.1);
      color: #ff7d18;
      border-radius: 2px;
    }

    .img_list {
      .new_img {
        width: 70px;
        height: 40px;
        border-radius: 4px;
        margin-right: 10px;
      }
    }

    .btn {
      width: 100%;
      display: flex;
      align-items: center;

      .pass {
        cursor: pointer;
        border: 1px solid #4e93fb;
        color: #4e93fb;
        height: 24px;
        box-sizing: border-box;
        display: block;
        border-radius: 4px;
        width: 50px;
        line-height: 24px;
        margin-right: 10px;
        text-align: center;
      }

      .un_pass {
        border: 1px solid #fb6956;
        color: #fb6956;
        height: 24px;
        cursor: pointer;
        box-sizing: border-box;
        display: block;
        border-radius: 4px;
        width: 50px;
        text-align: center;
        line-height: 24px;
      }
    }
  }

  .search {
    width: 100%;
    overflow: hidden;
    display: flex;
    margin: 20px 0;
    justify-content: flex-end;
  }

  .search_right {
    width: 560px;

    /deep/ .el-button {
      border: 1px solid #448aff;
      color: #fff;
      border-radius: 0;
      background-color: #448aff;
    }
  }

  .new_page {
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }

  .dialog_btn {
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
</style>
